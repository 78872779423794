import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";

// reducers
import user from "./reducers/user";

const rootReducer = combineReducers({
  user,
});

const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export default store;
export { setupStore };
