import React, { useState } from "react";
import { useEffect } from "react";
import { Navbar } from "components/Navbar";
import { Footer } from "components/Footer";
import { getBookData } from "api_service/bookData";
import { Link, useParams } from "react-router-dom";
import { NotFound } from "components/common";
import { CallToAction } from "components/common";
import "styles/gradients.css";

const Book = () => {
  const { id }: any = useParams();
  const [book, setBook] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { book, error } = await getBookData(id);
      setMessage(error);
      setBook(book);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (isLoading || !book) document.title = "Wreadr";
    else document.title = `Wreadr | ${book.title}`;
  }, [book, isLoading]);

  if (isLoading)
    return (
      <div className="text-xl h-screen flex justify-center items-center  from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
        <p>Loading...</p>
      </div>
    );
  if (!book)
    return (
      <div className="h-screen flex justify-center items-center">
        <NotFound message={message!} />
      </div>
    );

  //   preprocessing
  const authors: string = book.authors
    .map((author: any) => author.name)
    .join(", ");
  const genres: string = book.genres.map((genre: string) => genre).join(", ");
  const tags: string = book.tags.map((tag: string) => tag).join(", ");
  //   preprocessing

  return (
    <section className="relative overflow-hidden " id="book">
      {/* shape divider and bg */}
      <div className=" object-cover blur-md bg-gradient-3 absolute inset-0 -z-10"></div>

      <div className="px-6">
        <Navbar />

        <div className=" mt-0 sm:mt-20 flex items-center justify-center sm:space-x-40 flex-col sm:flex-row sm:mb-24 mb-0 sm:pb-2 pb-10">
          <div className="flex flex-col space-y-4 drop-shadow-2xl order-2 sm:order-1">
            <h1 className="sm:w-96 w-80 text-3xl font-oswald font-light sm:text-5xl pb-2 from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
              {book.title}
            </h1>
            <div className="flex flex-row space-x-1">
              <img
                className="h-4 rounded-full"
                src={book.addedBy.displayImage}
                alt=""
              />
              <Link to={`/users/${book.addedBy.id}`}>
                <p className="font-oswald font-light sm:text-sm text-xs">{`${book.addedBy.firstName} ${book.addedBy.lastName}`}</p>
              </Link>
            </div>
            <p className="mt-6 text-lg text-justify font-archivo sm:w-96 w-80">
              <span className="font-semibold mt-5">Authors: </span>
              {authors}
              <br />
              <span className="font-semibold mt-5">Genres: </span>
              {genres}
              <br />
              <span className="font-semibold mt-5">Tags: </span>
              {tags}
              <br />

              <span className="font-semibold mt-5">Description: </span>
              {book.description}
              <br />

              <span className="font-semibold mt-5 text-red-600">
                {book.transferred === true
                  ? "This book has been archived by the owner."
                  : ""}
              </span>
            </p>
          </div>

          <div className="sm:order-2 order-1">
            <img
              src={
                book.coverImage
                  ? book.coverImage
                  : "https://files.wreadr.com/assets/defaultBookCover.png"
              }
              className="transform transition hover:-translate-y-2 hover:drop-shadow-2xl sm:h-96 h-72 drop-shadow-lg sm:mt-0 mt-5 sm:mb-0 mb-5 rounded-lg"
              alt="wip"
            />
          </div>
          <CallToAction />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Book;
