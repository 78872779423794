import React from "react";
import { Link } from "react-router-dom";

const NotFound = ({ message }: { message: string }) => {
  return (
    <div className="flex flex-col justify-center items-center space-y-10">
      <h1 className="text-7xl font-oswald font-light sm:text-8xl from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
        404
      </h1>
      <p className="text-3xl font-inter text-center">{message}</p>
      <Link
        to="/"
        className="text-2xl from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out"
      >
        Go back to Home
      </Link>
    </div>
  );
};

export default NotFound;
