import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux_component/store";

// components
import { Home } from "./components/Home";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { Book } from "./components/Book";
import { User } from "components/User";
import AuthStateListener from "components/plugins/AuthStateListener";
import ScrollUtil from "api_service/util/scrollUtil";
import { NotFoundPage } from "components/NotFound";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollUtil />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/books/:id" element={<Book />} />
          <Route path="/users/:id" element={<User />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <AuthStateListener />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
