import { StoreLink } from "components/StoreLink";
import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <div className="sm:h-80 h-40 flex flex-col justify-center items-center sm:aspect-square rounded-xl order-3">
      <h1 className="text-center text-xl font-oswald font-light sm:text-2xl">
        Countless other books and friends are waiting. Download{" "}
        <Link
          to="/"
          className=" from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out"
        >
          Wreadr
        </Link>{" "}
        to get started.
      </h1>
      <div className="scale-95">
        <StoreLink />
      </div>
    </div>
  );
};

export default CallToAction;
