import React from "react";
import { useEffect } from "react";
import Hero from "./Hero";
import CTA from "./CTA";
import Introduction from "./Introduction";
import Features from "./Features";
import FAQ from "./FAQ";
import Contact from "./Contact";
import { Footer } from "components/Footer";

const Home = () => {
  useEffect(() => {
    document.title = "Wreadr | Connect with book lovers accross the globe";
  }, []);

  return (
    <div className="Home">
      <Hero />
      <CTA />
      <Introduction />
      <Features />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
