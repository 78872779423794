import React, { useEffect } from "react";
import { useTypedDispatch } from "redux_component/reduxHooks";
import { fetchCurrentUser } from "redux_component/thunks/user";

export default function AuthStateListener() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    // TODO: it will observe token updates etc.
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  return <div></div>;
}
