import React from "react";
import { Link } from "react-router-dom";
import logo from "images/wreadrLogo.svg";

const Navbar = () => {
  return (
    <div className="sm:mt-5 sm:ml-5 mt-5 w-52 ">
      <Link to="/" className="sm:p-1 sm:m-1 p-1">
        <img
          className="h-12 hover:-translate-y-1 transform transition"
          src={logo}
          alt="wreadr-logo"
        />
      </Link>
    </div>
  );
};

export default Navbar;
