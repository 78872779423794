import React from "react";
import hero from "images/heroImage.svg";
import { Navbar } from "components//Navbar";
import { StoreLink } from "components//StoreLink";
import "styles/gradients.css";

const Hero = () => {
  return (
    <section className="relative overflow-hidden " id="hero-section">
      {/* shape divider and bg */}
      <div className="h-screen bg-gradient-1 absolute inset-0 -z-10"></div>
      <div className="shape-divider-bottom">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="px-6">
        <Navbar />

        <div className=" mt-5 sm:mt-20 flex items-center justify-center sm:space-x-16 flex-col sm:flex-row sm:mb-40 mb-0 ">
          <div>
            <h1 className="h-36 text-3xl font-oswald font-light sm:text-6xl from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-black hover:text-transparent transition-colors duration-700 ease-in-out">
              Find books for exchange, giveaway
              <span className="font-oswald font-medium">
                <div className="p-1"></div>
                or meet a new book buddy
              </span>
            </h1>
            <p className=" lg:mt-0 sm:mt-32 text-md font-inter">
              Join a vast community of readers sharing their love for reading
              with each other.
              <br />
              Discover books people are offering for exchange or giving way near
              you!
            </p>
            <StoreLink />
          </div>

          <div>
            <img
              src={hero}
              className="sm:h-96 h-52 drop-shadow-md sm:mt-0 mt-10 "
              alt="hero"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
