import React from "react";
import questionImage from "images/questionImage.svg";
import Accordion from "./Accordion";
import { IFAQ } from "./types";
import "styles/gradients.css";

const FAQ = () => {
  const faqs: IFAQ[] = [
    {
      question: "What is the app for?",
      response:
        "Wreadr helps you find people near you who shares the same love of reading like you! You can find people sharing their book collection, and willing to exchange, give away or simply sell. You can message them, find a way that works for both of you to share your books and exchange ideas!",
    },
    {
      question: "How do I connect with people?",
      response:
        "If you find a book you are interested in, simply message the book owner. You can send only one message at first if you are not connected. As soon as the other user replies back, you two will be connected and can continue your conversation. So make sure you are giving a good impression at your first message!",
    },
    {
      question: "Can I upload digital copies of books?",
      response:
        "No. We only allow you to create posts of physical copies of books.",
    },
    {
      question:
        "Why my books aren't showing up on other people's feed or search result?",
      response:
        "Make sure you add at least one tag to your book (i.e. Exchange, Give away or Sell). If you don't add any tag, the book will be added only in your personal collection and will be visible only when someone visits your profile, but will not show up in anyone's feed or search.",
    },
    {
      question: "How much do I pay?",
      response: "Nothing! Our app is completely free!",
    },
  ];

  return (
    <section className="relative overflow-hidden " id="faq-section">
      {/* shape divider and bg */}
      <div className="h-[1800px] bg-gradient-1 absolute inset-0 -z-10"></div>
      <div className="shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="shape-divider-bottom">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="px-6">
        <div className="sm:h-[800px] flex items-center justify-center sm:space-x-80 flex-col sm:flex-row sm:mb-38 mb-32 mt-20 sm:mt-56  ">
          <div>
            {faqs.map((item, key) => (
              <Accordion key={key} item={item} />
            ))}
          </div>

          <div className="">
            <img
              src={questionImage}
              className="sm:h-80 h-48 drop-shadow-md sm:pr-0 sm:mt-0 mt-0"
              alt="question"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
