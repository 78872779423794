import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoadUserPayload, IUserState } from "./types";

const initialState: IUserState = {
  user: null,
  isLoggedIn: false,
  isLoading: true,
  errMsg: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUser: (state, action: PayloadAction<ILoadUserPayload>) => {
      const { isLoggedIn, user } = action.payload;
      state.user = user;
      state.isLoggedIn = isLoggedIn;
      state.isLoading = false;
      state.errMsg = null;
    },
    startLoadingUser: (state) => {
      state.isLoading = true;
      state.errMsg = null;
      state.isLoggedIn = false;
      state.user = null;
    },
    stopLoadingUserWithErrorMessage: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export const { startLoadingUser, loadUser, stopLoadingUserWithErrorMessage } =
  userSlice.actions;

export default userSlice.reducer;