import React from "react";
import { NotFound } from "components/common";
import { Navbar } from "components/Navbar";
import { Footer } from "components/Footer";

const NotFoundPage = () => {
  return (
    <section id="not-found-page">
      <div className="px-6">
        <Navbar />
        <div className="flex justify-center items-center sm:h-[560px] h-[475px]">
          <NotFound message="This page does not exist" />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default NotFoundPage;
