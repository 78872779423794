import React from "react";
import together from "images/together.svg";
import { StoreLink } from "components/StoreLink";

const Features = () => {
  return (
    <section
      className="relative overflow-hidden bg-white"
      id="features-section"
    >
      <div className="shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="sm:mt-0 mt-10 px-6 flex flex-col sm:flex-row h-screen justify-center items-center sm:space-x-28">
        <div>
          <img
            src={together}
            className="h-96 drop-shadow-md sm:px-0 px-12"
            alt="together"
          />
        </div>

        <div className="mt-6 sm:mt-20 flex items-center justify-center space-x-16 flex-col sm:flex-row mb-40">
          <div>
            <h1 className="text-4xl font-oswald font-light sm:text-6xl">
              Designed for{" "}
              <span className="from-sky-500 via-green-400 to-teal-300 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
                bibliophiles
              </span>
            </h1>
            <p className="mt-6 text-lg font-inter">
              Connect with booklovers across the globe.
              <br />
              Find your next best friend and your next best read!
            </p>
            <StoreLink />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
