import { AxiosError } from "axios";

const formatErrorMessage = (err: AxiosError) => {
  const message =
    // @ts-ignore
    err.response?.data?.message || err.response?.statusText || err.message;
  const code = err.response?.status;
  return { message, code };
};

export default formatErrorMessage;
