import React from "react";
import { useState } from "react";
import { IBookListProp } from "./types";
import  BookCard  from "./BookCard";
import { CallToAction } from "components/common";

const BookList = ({ title, books, message }: IBookListProp) => {
  const [showModal, setshowModal] = useState(false);
  return (
    <div>
      {books.length === 0 ? (
        <div className="drop-shadow-md">
          <p className="text-xl font-archivo font-bold text-center">{title}</p>
          <div className="h-64 my-2 flex justify-center items-center text-center">
            User hasn't added any books in this category.
          </div>
        </div>
      ) : (
        <div className="drop-shadow-md">
          <p className="text-xl font-archivo font-bold text-center">{title}</p>
          <div className="h-64 my-2 flex justify-start sm:justify-center space-x-0 sm:space-x-5 items-center overflow-y-hidden hover:overflow-x-scroll no-scrollbar">
            {showModal ? (
              <CallToAction />
            ) : (
              books.map((book) => (
                <BookCard
                  title={book.title}
                  description={book.description}
                  id={book.id}
                  coverImage={book.coverImage}
                  key={book.id}
                />
              ))
            )}
          </div>
        </div>
      )}

      {/* show marketing message for book count exceed */}
      {books.length >= 6 && (
        <p className="text-sm font-archivo font-bold text-center">
          <button
            className=" from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out"
            onClick={() => {
              setshowModal(!showModal);
            }}
          >
            {showModal ? "close modal" : message}
          </button>
        </p>
      )}
    </div>
  );
};

export default BookList;
