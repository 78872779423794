import { ThunkAction } from "redux_component/types";
import { loadUser, startLoadingUser } from "redux_component/reducers/user";

export const fetchCurrentUser = (): ThunkAction => async (dispatch) => {
  dispatch(startLoadingUser());

  setTimeout(() => {
    dispatch(
      loadUser({
        isLoggedIn: true,
        user: {
          id: "123",
          name: "John Doe",
          image: "",
        },
      })
    );
  }, 2000);
};
