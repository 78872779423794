import axios from "axios";

class AxiosWrapper {
  private config: {
    headers: {
      [key: string]: string;
    };
  };

  constructor() {
    this.config = { headers: {} };
  }

  updateToken(token: string | null) {
    if (token) {
      this.config.headers.authorization = `Bearer ${token}`;
    } else {
      delete this.config.headers.authorization;
    }
  }

  async get(url: string, query = {}, expectedCode = 200) {
    return await axios.get(url);
  }

  async post(url: string, data: any, expectedCode = 201) {
    return await axios.post(url, data, {
      ...this.config,
      validateStatus: (status) => status === expectedCode,
    });
  }

  async put(url: string, data: any, expectedCode = 200) {
    return await axios.put(url, data, {
      ...this.config,
      validateStatus: (status) => status === expectedCode,
    });
  }

  async patch(url: string, data: any, expectedCode = 204) {
    return await axios.patch(url, data, {
      validateStatus: (status) => status === expectedCode,
    });
  }

  async delete(url: string, expectedCode = 204) {
    return await axios.delete(url, {
      validateStatus: (status) => status === expectedCode,
    });
  }
}

const wrapper = new AxiosWrapper();

export default wrapper;
