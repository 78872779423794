import React from "react";
import { Link } from "react-router-dom";
import instagram from "images/instagram.png";
import facebook from "images/facebook.png";
import twitter from "images/twitter.png";
import linkedin from "images/linkedin.png";
import "styles/gradients.css";

const Footer = () => {
  return (
    <section
      className="p-2 sm:h-16 h-20 bg-gradient-to-r from-teal-200 to-lime-200 flex sm:flex-row font-inter items-center justify-around sm:text-sm text-xs flex-col"
      id="footer-section"
    >
      {/* wreadr */}
      <Link
        to="/"
        className=" from-sky-700 via-green-600 to-teal-500 bg-gradient-to-r bg-clip-text text-black hover:text-transparent transition-colors duration-700 ease-in-out"
      >
        <span className="">® Developed with </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="rgb(240,0,0)"
          className="w-4 h-4 inline"
        >
          <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
        </svg>
        <span className=""> by Wreadr</span>
      </Link>

      {/* pp and tos */}
      <div className="flex flex-row items-center justify-center space-x-2">
        <Link to="/privacy-policy">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 inline"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>

          <span className=""> Privacy Policy</span>
        </Link>

        <Link to="/terms-of-service">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 inline"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <span className=""> Terms of Service</span>
        </Link>
      </div>

      {/* socials */}

      <div className="flex flex-row items-center justify-center space-x-2">
        <span>Socials: </span>
        <a href="https://www.facebook.com/WreadrApp/" target="_blank" rel="noreferrer">
          <img src={facebook} alt="facebook-logo" className="w-4 h-4 inline" />
        </a>
        <a href="https://www.instagram.com/wreadrapp/" target="_blank" rel="noreferrer">
          <img
            src={instagram}
            alt="instagram-logo"
            className="w-4 h-4 inline"
          />
        </a>
        <a href="https://twitter.com/wreadrapp" target="_blank" rel="noreferrer">
          <img src={twitter} alt="twitter-logo" className="w-4 h-4 inline" />
        </a>
        <a href="https://www.linkedin.com/company/wreadr/about/" target="_blank" rel="noreferrer">
          <img src={linkedin} alt="linkedin-logo" className="w-4 h-4 inline" />
        </a>
      </div>
    </section>
  );
};

export default Footer;
