import React from "react";
import chat from "images/chatImage.svg";
import {StoreLink} from "components/StoreLink";

const CTA = () => {
  return (
    <section className="relative overflow-hidden bg-white" id="cta-section">
      <div className=" px-6 flex flex-col sm:flex-row h-screen justify-center items-center sm:space-x-28">
        <div>
          <img
            src={chat}
            className="sm:mt-0 mt-10 sm:h-96 h-72 drop-shadow-md sm:px-0 px-12 "
            alt="chat"
          />
        </div>

        <div className="mt-10 sm:mt-20 flex items-center justify-center sm:space-x-16 flex-col sm:flex-row mb-32">
          <div>
            <h1 className="text-4xl font-oswald font-light sm:text-6xl">
              Rediscover your{" "}
              <span className="from-sky-500 via-green-400 to-teal-300 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
                passion
              </span>
            </h1>
            <p className="mt-6 text-lg font-inter">
              Knowing people of similar interests can help you revive your
              spirit for reading.
            </p>
            <StoreLink />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
