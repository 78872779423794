import { AxiosError } from "axios";
import { axiosWrapper, formatErrorMessage } from "./util";
import { IBookCardProp } from "components/BookCollection/types";

const route = "/api/users";

export const getUserData = async (userId: string) => {
  try {
    const { data } = await axiosWrapper.get(`${route}/${userId}`);
    return { user: data };
  } catch (err) {
    const { message, code } = formatErrorMessage(err as AxiosError);
    return { error: message, code: code, user: null };
  }
};

export const getUserBookCollection = async (
  userId: string,
  bookTag: "Exchange" | "Giveaway" | "Sale" | "",
  limit: number = 6
) => {
  try {
    const { data } = (await axiosWrapper.get(
      `${route}/${userId}/books?tag=${bookTag}&limit=${limit}`
    )) as { data: IBookCardProp[] };
    return { books: data };
  } catch (err) {
    const { message, code } = formatErrorMessage(err as AxiosError);
    return { error: message, code: code, books: null };
  }
};
