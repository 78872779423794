import React, { useState } from "react";
import { IFAQ } from "./types";

const Accordion = ({ item }: { item: IFAQ }) => {
  const [data, setData] = useState(item);
  const handleToggle = () => {
    const newIsActive = !data.isActive;
    setData({ ...data, isActive: newIsActive });
  };

  return (
    <div
      className={`rounded-xl font-oswald font-light text-white sm:w-[450px] w-80 p-5 bg-teal-700 border mb-5 group ${
        data.isActive ? "is-active" : ""
      }`}
    >
      <div className="flex items-center cursor-pointer " onClick={handleToggle}>
        <div className="w-full group-[.is-active]:font-medium text-md text-justify">
          Q. {data.question}
        </div>

        <div className="ml-5 rotate-0  duration-300 group-[.is-active]:rotate-[180deg]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div className="overflow-hidden duration-300 max-h-0 group-[.is-active]:max-h-48 text-md text-justify">
        A. {data.response}
      </div>
    </div>
  );
};
export default Accordion;
