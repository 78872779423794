import React, { useState } from "react";
import { useEffect } from "react";
import { Navbar } from "components/Navbar";
import { Footer } from "components/Footer";
import { BookList } from "components/BookCollection";
import { IBookCardProp } from "components/BookCollection/types";
import { useParams } from "react-router-dom";
import { getUserData, getUserBookCollection } from "api_service/userService";
import { NotFound } from "components/common";
import "styles/gradients.css";

const User = () => {
  const { id }: any = useParams();
  const [user, setUser] = useState<any>(null);
  const [isBioLong, setIsBioLong] = useState(false);
  const [isBioExpanded, setIsBioExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isBookCollectionNull, setIsBookCollectionNull] = useState(false);

  const [giveawayBooks, setGiveawayBooks] = useState<IBookCardProp[]>([]);
  const [exchangeBooks, setExchangeBooks] = useState<IBookCardProp[]>([]);
  const [saleBooks, setSaleBooks] = useState<IBookCardProp[]>([]);
  const [personalCollection, setPersonalCollection] = useState<IBookCardProp[]>(
    []
  );

  // fetch user and user book collection
  useEffect(() => {
    const fetchAllData = async () => {
      const { user, error } = await getUserData(id);
      if (error) {
        setMessage(error);
        setIsLoading(false);
        return;
      }
      setUser(user);
      if (user.bio) {
        if (user.bio.length >= 407) setIsBioLong(true);
      } else {
        user.bio = `User hasn't shared any information.`;
      }
      const giveawayBooksPromise = getUserBookCollection(id, "Giveaway");
      const exchangeBooksPromise = getUserBookCollection(id, "Exchange");
      const saleBooksPromise = getUserBookCollection(id, "Sale");
      const personalCollectionPromise = getUserBookCollection(id, "");

      try {
        const data = await Promise.all([
          giveawayBooksPromise,
          exchangeBooksPromise,
          saleBooksPromise,
          personalCollectionPromise,
        ]);

        const fetchedGiveawayBooks = data[0].books!;
        const fetchedExchangeBooks = data[1].books!;
        const fetchedSaleBooks = data[2].books!;
        const fetchedPersonalCollection = data[3].books!;

        setGiveawayBooks(fetchedGiveawayBooks);
        setExchangeBooks(fetchedExchangeBooks);
        setSaleBooks(fetchedSaleBooks);
        setPersonalCollection(fetchedPersonalCollection);
      } catch (err) {
        setMessage("Could not fetch books");
        setIsBookCollectionNull(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [id]);

  useEffect(() => {
    if (isLoading || !user) document.title = "Wreadr";
    else document.title = `Wreadr | ${user.firstName} ${user.lastName}`;
  }, [user, isLoading]);

  if (isLoading)
    return (
      <div className="text-xl h-screen flex justify-center items-center  from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
        <p>Loading...</p>
      </div>
    );
  if (!user || isBookCollectionNull)
    return (
      <div className="h-screen flex justify-center items-center">
        <NotFound message={message!} />
      </div>
    );

  return (
    <section className="relative overflow-hidden " id="book">
      {/* shape divider and bg */}
      <div className=" object-cover blur-md bg-gradient-3 absolute inset-0 -z-10"></div>

      <div className="px-6 pb-4">
        <Navbar />

        <div className=" flex items-center justify-center sm:space-x-40 flex-col sm:flex-row sm:mb-14 mb-0 sm:pb-0 pb-10">
          <div className=" justify-center flex sm:flex-row flex-col space-y-4 drop-shadow-xl sm:space-x-10 space-x-0">
            <img
              className="sm:h-72 sm:w-72 rounded-full h-48 w-48"
              src={
                user.displayImage
                  ? user.displayImage
                  : "https://files.wreadr.com/assets/defaultDisplayImage.png"
              }
              alt=""
            />
            <div className="flex flex-col sm:w-[550px] w-72 space-y-2">
              <h1 className="sm:w-96 w-80 text-4xl font-oswald font-light sm:text-5xl pb-2 from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent hover:text-black transition-colors duration-700 ease-in-out">
                {`${user.firstName} ${user.lastName}`}
              </h1>

              <p className="text-lg text-justify font-archivo font-bold">
                {/* work / school */}
              </p>
              {/* bio length exceeds limit */}
              {isBioLong && (
                <p className="text-lg text-justify font-archivo">
                  {/* if not expanded, show trimmed version */}
                  {!isBioExpanded && user.bio.slice(0, 407)}

                  {/* if expanded, show original */}
                  {isBioExpanded && user.bio}

                  <span
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      setIsBioExpanded(!isBioExpanded);
                    }}
                  >
                    {" "}
                    {!isBioExpanded ? "see more..." : "see less..."}
                  </span>
                </p>
              )}
              {/* bio length doesn't exceed limit */}
              {!isBioLong && (
                <p className="text-lg text-justify font-archivo">{user.bio}</p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-5 transform -translate-y-5">
          {/* exchange books */}
          <BookList
            title="For Exchange"
            books={exchangeBooks}
            message="view more books available for exchange..."
          />

          {/* giveaway books */}
          <BookList
            title="For Give Away"
            books={giveawayBooks}
            message="view more books available for giveaway..."
          />

          {/* sale books */}
          <BookList
            title="For Sale"
            books={saleBooks}
            message="view more books available for sale..."
          />

          {/* all books */}
          <BookList
            title="Personal Collection"
            books={personalCollection}
            message="view more..."
          />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default User;
