import React from "react";
import { Link } from "react-router-dom";
import { IBookCardProp } from "./types";
import { getCompactText } from "components/util";

const BookCard = ({ id, title, description, coverImage }: IBookCardProp) => {
  return (
    <Link to={`/books/${id}`}>
      <div className="flex flex-col w-36 hover:-translate-y-2 transition-transform scale-[.85] ">
        <img
          className="rounded-lg w-36 h-56 object-cover"
          src={
            coverImage
              ? coverImage
              : "https://files.wreadr.com/assets/defaultBookCover.png"
          }
          alt="displayImage"
        />
        <p className="text-sm mt-1 font-archivo font-bold">
          {getCompactText(title, 25)}
        </p>
        <p className="text-xs text-justify font-archivo h-2">
          {description ? getCompactText(description, 56) : "..."}
        </p>
      </div>
    </Link>
  );
};

export default BookCard;
