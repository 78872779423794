import React from "react";
import books from "images/bookShelves.svg";
import "styles/gradients.css";

const Introduction = () => {
  return (
    <section className="relative overflow-hidden " id="introduction-section">
      {/* shape divider and bg */}
      <div className="h-screen bg-gradient-2 absolute inset-0 -z-10"></div>
      <div className="shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="shape-divider-bottom">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="px-6">
        <div className="mt-28 sm:mt-48 flex items-center justify-center sm:space-x-36 flex-col sm:flex-row sm:mb-40 mb-20">
          <div>
            <h1 className="text-4xl font-oswald font-light sm:text-6xl from-sky-600 via-green-500 to-teal-400 bg-gradient-to-r bg-clip-text text-black hover:text-transparent transition-colors duration-700 ease-in-out">
              Books have more
              <br />
              stories to tell
            </h1>
            <p className="mt-6 text-lg font-inter">
              Turn your old books into cash or kindness.
              <br />
              Trade, sell or donate them to others.
            </p>
          </div>

          <div>
            <img
              src={books}
              className="sm:h-80 h-64 drop-shadow-md sm:pr-0 px-12 sm:mt-0 "
              alt="books"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
