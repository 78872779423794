import React from "react";
import appStore from "images/appStore.svg";
import appStoreBlank from "images/appStoreBlank.svg";
import playStore from "images/playStore.svg";

const StoreLink = () => {
  return (
    <div className="mt-6 flex space-x-2" id="store-link">
      <div className="">
        <a
          href="/"
          className="hover:cursor-not-allowed"
          onClick={(e) => e.preventDefault()}
        >
          <img
            src={appStore}
            alt="appStore"
            className="h-12 hover:-translate-y-1 transform transition-all "
            onMouseOver={(e) => (e.currentTarget.src = appStoreBlank)}
            onMouseLeave={(e) => (e.currentTarget.src = appStore)}
          />
        </a>
      </div>

      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.wreadr.android"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={playStore}
            alt="playStore"
            className="h-12 hover:-translate-y-1 transform transition-all "
          />
        </a>
      </div>
    </div>
  );
};

export default StoreLink;
