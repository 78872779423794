import { AxiosError } from "axios";
import { axiosWrapper, formatErrorMessage } from "./util";

const route = "/api/books";

export const getBookData = async (bookId: string) => {
    
    try {
        const { data } = await axiosWrapper.get(`${route}/${bookId}`);
        return { book: data };
    } catch (err) {
        const { message, code } = formatErrorMessage(err as AxiosError);
        return { error: message, code: code, book: null };
    }

};
